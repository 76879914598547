@tailwind base;
@tailwind components;
@tailwind utilities;
.input{
  font-weight:300;
}
input:checked ~ .dot {
    transform: translateX(100%);
  }
  nav {
    position: relative;
  }
  
  .underline-animation {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 50px; /* Adjust to match the length of the link text */
    background-color: #3b82f6; /* Tailwind's blue-500 */
    transition: transform 0.3s ease-in-out;
  }
  
  nav a {
    position: relative;
    padding-bottom: 8px; /* Adjust to match Tailwind pb-2 */
    font-size: 16px; /* Adjust based on your design */
    color: #000; /* Tailwind's default text color */
    text-decoration: none;
  }
  
  nav a.active ~ .underline-animation {
    transform: translateX(0%);
  }
  
  nav a:nth-child(2).active ~ .underline-animation {
    transform: translateX(100%); /* Adjust based on the link width */
  }
  
  nav a:hover ~ .underline-animation {
    transform: translateX(0%); /* Adjust the percentage based on links */
  }
  
  nav a:nth-child(2):hover ~ .underline-animation {
    transform: translateX(100%); /* Adjust based on the link width */
  }
  